import React, { useEffect } from 'react';
import '../styles/Footer.css';
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLinkedin, faYoutube } from '@fortawesome/free-brands-svg-icons';
import { useTranslation } from 'react-i18next';

const Footer = () => {
  const { t, i18n } = useTranslation(); // Get the t and i18n objects

  useEffect(() => {

    const map = new window.google.maps.Map(document.getElementById('map'), {
      center: { lat: 28.9912208, lng: 77.6565947 }, // Replace with your coordinates
      zoom: 15, // Adjust the zoom level as needed
    });


    const marker = new window.google.maps.Marker({
      position: { lat: 28.9912208, lng: 77.6565947 }, // Replace with your coordinates
      map: map,
      title: 'regtechEng Location',
    });
  }, []);

 return (
   <footer className="footer">
     <div className="footer-content">


           <div className="footer-left">
                 <div className="footer-logo">
                   <Link to="/" className="nav-link">
                     <img src="/images/logo.png" alt="Your Logo" style={{ width: '125px', height: '64px' }} />
                   </Link>
                 </div>


                 <div className="footer-address">
                   <a
                     href="https://www.google.com/maps/place/regtechEng/@28.9912208,77.6565947,15z/data=!4m6!3m5!1s0x390c65fbfba973a5:0x18de64bfdd552317!8m2!3d28.9912208!4d77.6565947!16s%2Fg%2F11vdkylrpx?entry=ttu"
                     target="_blank"
                     rel="noopener noreferrer"
                   >
                     {t('Address1')}<br />
                     {t('Address2')}<br />
                     {t('Address3')}
                   </a>
                 </div>


                 <div className="footer-email">
                   <a href="mailto:contact@regtecheng.com">contact@regtecheng.com</a>
                 </div>


                 <div className="footer-social">
                       <a href="https://www.youtube.com/@regtechEng" className="footer-icon youtube-icon">
                            <FontAwesomeIcon icon={faYoutube} /> {/* Font Awesome YouTube icon */}
                       </a>
                       <a href="https://www.linkedin.com/company/regtecheng/" className="footer-icon linkedin-icon">
                             <FontAwesomeIcon icon={faLinkedin} /> {/* Font Awesome LinkedIn icon */}
                       </a>
                        <a href="https://www.google.com/search?q=regtechEng" className="google-icon">
                              <img src={process.env.PUBLIC_URL + "/google.png"} alt="Google" />
                        </a>

                       <a href="https://www.ambitionbox.com/overview/regtech-engineering-overview" className="ambition-icon">
                               <img src={process.env.PUBLIC_URL + "/ambition.png"} alt="Freelancer" />
                       </a>
                       <a href="https://github.com/regtechEng" className="github-icon">
                                <img src={process.env.PUBLIC_URL + "/github.png"} alt="Github" />
                        </a>

                 </div>

           </div>

            <div className="map-container">
                <div id="map" className="map">
                </div>
            </div>
     </div>
   </footer>
 );


};

export default Footer;
