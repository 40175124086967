import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import '../styles/Header.css';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import '@fortawesome/fontawesome-free/css/all.min.css';
import { useTranslation } from 'react-i18next';

const Header = () => {
  const [popupVisible, setPopupVisible] = useState(false);

  const { t, i18n } = useTranslation(); // Get the t and i18n objects

  const togglePopup = () => {
    setPopupVisible(!popupVisible);
  };

  const switchLanguage = () => {
    if (i18n.language === 'en') {
      i18n.changeLanguage('hindi');
    } else {
      i18n.changeLanguage('en');
    }
  };

  return (
    <header className="header">
    {/* Brand*/}

       <div className="brand">
              {/* Translated brand text */}
              <Link to="/" className="nav-link">
                <img src={process.env.PUBLIC_URL + "/images/logo.png"} alt="Your Logo" style={{ width: '125px', height: '64px' }} />
              </Link>
            </div>
       {/* navbar */}
      <div className="navbar">

         <div className="language-start-container">

             <button onClick={switchLanguage} className="language-button">{/* language button*/}
               {i18n.language === 'en' ? 'हि' : 'En'}
             </button>
             <button onClick={togglePopup} className="button">{t('Start')}</button> {/* start button*/}
           </div>


      </div>


      {popupVisible && (
        <div className="popup-overlay">
          <div className="popup-content">
            <button onClick={togglePopup} className="close-button">
              <i className="fas fa-times"></i>
            </button>
            <div className="popup-links">


              <Link to="/contact" className="popup-link">{t('Contact')}</Link>
            </div>
          </div>
        </div>
      )}
    </header>
  );
};

export default Header;
