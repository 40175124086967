import React from 'react';
import Header from './Header';
import Footer from './Footer';
import '../styles/Contact.css';
import { useTranslation } from 'react-i18next';


const Contact = () => {


const { t, i18n } = useTranslation();


  return (
    <div>
      <Header />
      <div className="start-content">
        <h2>{t('Contact')}</h2>
        <form>
          <div className="form-group">
            <label htmlFor="name">{t('Name')}</label>
            <input type="text" id="name" name="name" value={t('Hello')} readOnly />
          </div>
          <div className="form-group">
            <label htmlFor="email">{t('Email')}</label>
            <input type="email" id="email" name="email" value={t('EmailM')} readOnly />
          </div>
          <div className="form-group">
            <label htmlFor="message">{t('Message')}</label>
            <textarea id="message" name="message" rows="4" value={t('MessageM')} readOnly />
          </div>
          <div className="footer-social">
           <a href="https://www.freelancer.com/manage/jobs/projects/open" className="footer-icon">
            <img src="/freelencer.png" alt="Freelancer" />
            </a>
           </div>
        </form>

      </div>
      <Footer />
    </div>
  );
};

export default Contact;
