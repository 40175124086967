import React, { useState } from 'react';
import Header from './Header';
import Footer from './Footer';
import Carsol from './Carsol';
import '../styles/HomePage.css';

import { useTranslation } from 'react-i18next';

const HomePage = () => {
  const { t, i18n } = useTranslation();

  return (
    <div>
      <Header />
      <div className='home-content'>
       <Carsol/>
       </div>
      <Footer />
    </div>
  );
};

export default HomePage;
