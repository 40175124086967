import React from 'react';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import '../styles/HomePage_caursol.css';

const Carsol = () => {
  return (
    <div className='carousel-container'>
      <Carousel autoPlay={true} interval={3000} infiniteLoop={true}>
        <a href="https://ganguram.com/pan-india/">
          <div>
            <img src={process.env.PUBLIC_URL + "/images/image1.png"} alt="Image 1" />
          </div>
        </a>
        <a href="https://vetic.in/">
          <div>
            <img src={process.env.PUBLIC_URL + "/images/image2.png"} alt="Image 2" />
          </div>
        </a>
        <a href="https://www.toysrus.in/">
          <div>
            <img src={process.env.PUBLIC_URL + "/images/image3.png"} alt="Image 3" />
          </div>
        </a>
      </Carousel>
    </div>
  );
};

export default Carsol;
